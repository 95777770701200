import { BaseModuleProps, PageContent } from '../../types';
import React from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';

const RichText: React.FC<
    Omit<BaseModuleProps, 'pageContent'> & {
        pageContent: Pick<PageContent['content'], 'text'>;
    }
> = ({ pageContent, moduleHead }) => {
    const { textUtil } = useSiteData();
    const textContent = textUtil.formatText(pageContent.text);

    const className = 'richtext abstractComponent';

    return (
        <div className={className}>
            {moduleHead}
            <div
                className="m-rich-text "
                data-t-name="RichText"
                data-t-id="15"
                dangerouslySetInnerHTML={{ __html: textContent }}
            />
        </div>
    );
};

export default RichText;
